import { graphql } from 'gatsby'
import React from 'react'

import img1 from '../../assets/blog/24/1.gif'
import img2 from '../../assets/blog/24/2.gif'
import img3 from '../../assets/blog/24/3.png'
import img4 from '../../assets/blog/24/4.png'
import heroImg from '../../assets/blog/24/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Pět kroků k lepší efektivitě" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Pět kroků k lepší efektivitě" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Pět kroků k lepší efektivitě"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            Každý den máme k dispozici stejný počet hodin, ale ne každý den jsme
            stejně produktivní. Co dělat, abychom svůj čas využili co nejlépe?
          </p>
          <h3>1. Plánujte si den dopředu</h3>
          <p>
            <ul>
              <li>Večer si sepište seznam úkolů a cílů na další den.</li>
              <li>
                Stanovte si priority - nejdůležitější úkoly dejte na první
                místo.
              </li>
              <li>Realisticky odhadněte, kolik času vám zabere každý úkol.</li>
            </ul>
          </p>
          <h3>2. Začněte den důležitými úkoly</h3>
          <p>
            <ul>
              <li>
                Ráno se pusťte do nejdůležitějších a nejnáročnějších úkolů.
              </li>
              <li>Využijte toho, že jste odpočatí a máte nejvíce energie.</li>
              <li>
                Odkládání těžkých úkolů vede jen ke stresu a prokrastinaci.
              </li>
            </ul>
          </p>
          <h3>3. Soustřeďte se vždy jen na jeden úkol</h3>
          <p>
            <ul>
              <li>
                Multitasking je mýtus, ve skutečnosti snižuje produktivitu.
              </li>
              <li>Věnujte se naplno jedné věci, dokud ji nedokončíte.</li>
              <li>
                Nenechte se rozptylovat - vypněte upozornění, zablokujte rušivé
                weby.
              </li>
            </ul>
          </p>
          <h3>4. Dělejte si pravidelné přestávky</h3>
          <p>
            <ul>
              <li>Každou hodinu si udělejte 5-10 minut pauzu.</li>
              <li>Protáhněte se, projděte se, doplňte tekutiny.</li>
              <li>Krátký oddech pomůže obnovit koncentraci a energii.</li>
            </ul>
          </p>
          <h3>5. Používejte nástroje pro správu úkolů</h3>
          <p>
            <ul>
              <li>
                Aplikace jako Flagis vám pomohou udržet přehled o úkolech a
                dalších pro vás důležitých věcech.
              </li>
              <li>
                Úkoly si třiďte do kategorií a nastavte si připomínky, abyste na
                nic nezapomněli.
              </li>
              <li>
                Delegujte - posílejte úkoly v rámci jedné aplikace. Tím
                neztratíte přehled o svých úkolech, ani o těch delegovaných.
              </li>
            </ul>
          </p>

          <div className="my-5">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="1" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img2} target="_blank" rel="noreferrer">
              <img src={img2} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img3} target="_blank" rel="noreferrer">
              <img src={img3} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img4} target="_blank" rel="noreferrer">
              <img src={img4} alt="2" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
